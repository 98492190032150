import { ChangeEvent, FC, memo } from 'react';
import styled from 'styled-components';
import {
  dangerColor,
  greyColor,
  primaryColor,
  whiteColor,
} from 'common/themes/colors';

type Props = {
  placeholder?: string;
  defaultValue?: string;
  type?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  width?: number;
  isError?: boolean;
  autoComplete?: string;
};
export const Input: FC<Props> = memo((props) => {
  return (
    <RootStyle
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      type={props.type}
      autoComplete={props.autoComplete}
      onChange={props.handleChange}
      width={props.width}
      $isError={props.isError}
    />
  );
});

const RootStyle = styled.input<{ width?: number; $isError?: boolean }>`
  padding: 8px;
  background: ${whiteColor};
  border-radius: 8px;
  border: ${(props: { $isError?: boolean }) =>
    `${props.$isError ? dangerColor : greyColor} solid 0.5px`};
  width: ${(props: { width?: number }) =>
    props.width ? `${props.width}px` : 'auto'};

  &:focus-visible {
    outline: 2px ${primaryColor} solid;
    border: none;
  }
`;
