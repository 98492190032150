import { useCallback, useContext } from 'react';
import { ProgressContext } from 'providers/useProgressProvider';

export const useProgress = () => {
  const { isShow, setShow } = useContext(ProgressContext);
  const pushProgress = useCallback(() => {
    setShow(isShow + 1);
  }, [isShow, setShow]);
  const removeProgress = useCallback(() => {
    if (isShow <= 1) {
      setShow(0);
    } else {
      setShow(isShow - 1);
    }
  }, [isShow, setShow]);
  return { isShow, pushProgress, removeProgress };
};
