import { FC, memo } from 'react';
import { Columns, Rows } from 'components/atoms';
import { Table } from 'domain/entity';
import styled from 'styled-components';
import {
  blackColor,
  primary70Color,
  primaryColor,
  whiteColor,
} from 'common/themes/colors';
import { tableStatus } from 'common/codes/table.status';
import { FreeDetail } from 'components/modules/top';

type Props = {
  table: Table;
};
export const TableCard: FC<Props> = memo((props) => {
  return (
    <RootStyle $status={props.table.status}>
      <Columns position={'center'} height={'100%'} width={'100%'} space={16}>
        <Rows space={4} width={'100%'} justifyContent={'space-evenly'}>
          <TitleStyle>{`No.${props.table.tableNo.toString()}`}</TitleStyle>
          <TitleStyle>
            {props.table.status !== 3 && '利用中：'}
            {tableStatus[props.table.status]}
          </TitleStyle>
        </Rows>
        <Rows
          space={32}
          justifyContent={'center'}
          alignItem={'start'}
          width={'100%'}
        >
          <Columns position={'center'} space={16}>
            <SubTitleStyle>ゲーム数</SubTitleStyle>
            <Columns position={'start'} space={8}>
              {props.table.freeGameCount3PlayersA && (
                <Rows>
                  <LabelStyle>サンマA：</LabelStyle>
                  <span>{props.table.freeGameCount3PlayersA ?? 0}</span>
                </Rows>
              )}
              {props.table.freeGameCount3PlayersB && (
                <Rows>
                  <LabelStyle>サンマB：</LabelStyle>
                  <span>{props.table.freeGameCount3PlayersB ?? 0}</span>
                </Rows>
              )}
              {props.table.freeGameCount4PlayersA && (
                <Rows>
                  <LabelStyle>ヨンマA：</LabelStyle>
                  <span>{props.table.freeGameCount4PlayersA ?? 0}</span>
                </Rows>
              )}
              {props.table.freeGameCount4PlayersB && (
                <Rows>
                  <LabelStyle>ヨンマB：</LabelStyle>
                  <span>{props.table.freeGameCount4PlayersB ?? 0}</span>
                </Rows>
              )}
            </Columns>
          </Columns>
          {props.table.status == 1 && props.table.freeGameDetails && (
            <FreeDetail
              freeGameFirstDealerSeat={props.table.freeGameFirstDealerSeat ?? 0}
              freeGameDetails={props.table.freeGameDetails}
            />
          )}
          {props.table.status == 2 && props.table.rentalDetails && (
            <Columns position={'center'} space={16}>
              <SubTitleStyle>オーダー</SubTitleStyle>
              <Columns position={'start'} width={'200px'} space={8}>
                <Rows>
                  <LabelStyle>開始時刻：</LabelStyle>
                  <span>{props.table.rentalDetails.startTime ?? ''}</span>
                </Rows>
                <Rows>
                  <LabelStyle>顧客名：</LabelStyle>
                  <span>{props.table.rentalDetails.customerName ?? ''}</span>
                </Rows>
                <Rows>
                  <LabelStyle>現在の金額：</LabelStyle>
                  <span>
                    {props.table.rentalDetails.currentTotal?.toLocaleString() ??
                      0}
                    円
                  </span>
                </Rows>
                <Rows width={'100%'}>
                  <LabelStyle>備考：</LabelStyle>
                  <LineStyle>{props.table.rentalDetails.memo ?? ''}</LineStyle>
                </Rows>
              </Columns>
            </Columns>
          )}
        </Rows>
      </Columns>
    </RootStyle>
  );
});

const colorByStatus = (status: number) => {
  switch (status) {
    case 3:
      return whiteColor;
    case 1:
      return primaryColor;
    default:
      return primary70Color;
  }
};

const RootStyle = styled.div<{ $status: number }>`
  padding: 16px;
  width: 360px;
  min-height: 160px;
  border-radius: 16px;
  border: 1px solid ${blackColor};
  -webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  background: ${(props) => colorByStatus(props.$status)};
`;

const TitleStyle = styled.span`
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
`;

const SubTitleStyle = styled.span`
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
`;

const LabelStyle = styled.span`
  white-space: nowrap;
`;

const LineStyle = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
