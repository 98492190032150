import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

export const useHeader = () => {
  const [cookies, , deleteCookie] = useCookies(['token']);
  const navigate = useNavigate();
  useEffect(() => {
    if (!cookies.token) {
      navigate('/login');
    }
  }, [cookies, navigate]);

  const handlerLogout = useCallback(() => {
    deleteCookie('token', { path: '/' });
    navigate('/login');
  }, [navigate, deleteCookie]);

  return { handlerLogout };
};
