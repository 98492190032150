import { Columns, Link } from 'components/atoms';

export const Page404 = () => {
  return (
    <Columns position={'center'} width={'100%'} height={'100%'}>
      <h1>404 NOT FOUND</h1>
      <p>お探しのページが見つかりませんでした。</p>
      <Link href="/">Topに戻る</Link>
    </Columns>
  );
};
