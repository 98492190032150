import { FC, memo } from 'react';
import { useProgress } from 'hooks/common/useProgress';
import styled, { keyframes } from 'styled-components';
import { blackColor, primaryColor } from 'common/themes/colors';

export const Progress: FC = memo(() => {
  const { isShow } = useProgress();
  return (
    <RootStyle $isShow={isShow}>
      <ChildStyle />
    </RootStyle>
  );
});

const RootStyle = styled.div<{ $isShow: number }>`
  position: fixed;
  display: ${(props) => (props.$isShow > 0 ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${blackColor}90;
  z-index: 999;
`;

const AnimateSpin = keyframes`
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
`;

const ChildStyle = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid ${primaryColor};
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: ${AnimateSpin} 0.5s linear 0s infinite;
`;
