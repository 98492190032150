import client, { getToken } from 'infrastructures/axios/axios.client';
import { Sale } from 'domain/entity/sale';
import { TableList } from 'domain/entity';
import { CustomerList } from 'domain/entity/customer';

export const TopRepository = {
  getSale: async (): Promise<Sale> => {
    return await client
      .get<{ results: Sale }>('/sale', {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((data) => data.data.results);
  },
  getTables: async (): Promise<TableList> => {
    return await client
      .get<TableList>('/table/list', {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((data) => data.data);
  },
  getCustomers: async (): Promise<CustomerList> => {
    return await client
      .get<CustomerList>('/customer/list', {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((data) => data.data);
  },
  getLeaveCustomers: async (): Promise<CustomerList> => {
    return await client
      .get<CustomerList>('/customer/leave/list', {
        headers: {
          Authorization: getToken(),
        },
      })
      .then((data) => data.data);
  },
};
