import { FC, memo } from 'react';
import styled from 'styled-components';
import { Columns, Label, Rows, Spacer } from 'components/atoms';
import { CommonModules, TopModules } from 'components/modules';
import { TableCard } from 'components/modules/top';
import { Container, Grid } from 'components/molecutes';
import { useTopHook } from 'hooks/top/top.hook';
import { blackColor } from 'common/themes/colors';
import { CustomerRow } from 'components/modules/top/customer.row';

export const TopPage: FC = memo(() => {
  const { sale, customers, tables, leaveCustomers } = useTopHook();
  return (
    <RootStyle>
      <Columns position={'center'} width={'100%'}>
        <CommonModules.Header />
        <Spacer size={32} />
        <Columns position={'start'} space={16} width={'fit-content'}>
          <TopModules.Timestamp
            latestUpdateAt={new Date()}
            latestSummaryCreatedAt={sale.latestSummaryCreatedAt}
          />
          <TopModules.SalesTotal
            salesTotal={sale.saleTotal}
            freeSalesTotal={sale.saleTotalFreeGame}
          />
        </Columns>
        <Spacer size={32} />
        <div>
          <Grid padding={32}>
            <>
              {tables.results.map((table, index) => (
                <TableCard key={index} table={table} />
              ))}
            </>
          </Grid>
        </div>
        <Spacer size={32} />
        <Rows width={'600px'} space={32} alignItem={'top'}>
          <Columns position={'start'} width={'50%'} space={8}>
            <Label htmlFor={'customers'}>来店一覧</Label>
            {customers.results.length > 0 && (
              <Container
                border={`1px solid ${blackColor}`}
                padding={'8px'}
                width={'calc(100% - 16px)'}
              >
                <>
                  {customers.results.map((customer, index) => (
                    <CustomerRow customer={customer} key={index} />
                  ))}
                </>
              </Container>
            )}
            {!customers.results.length && <div>現在来店者はいません</div>}
          </Columns>
          <Columns position={'start'} width={'50%'} space={8}>
            <Label htmlFor={'customers'}>退店一覧</Label>
            {leaveCustomers.results.length > 0 && (
              <Container
                border={`1px solid ${blackColor}`}
                padding={'8px'}
                width={'calc(100% - 16px)'}
              >
                <>
                  {leaveCustomers.results.map((customer, index) => (
                    <CustomerRow customer={customer} key={index} />
                  ))}
                </>
              </Container>
            )}
            {!leaveCustomers.results.length && <div>現在退店者はいません</div>}
          </Columns>
        </Rows>

        <Spacer size={16} />
      </Columns>
    </RootStyle>
  );
});

const RootStyle = styled.div`
  margin: auto;
  height: 100%;
`;
