import { FC, memo } from 'react';
import styled from 'styled-components';
import { Input, Columns, Button } from 'components/atoms';
import { InputGroup } from 'components/molecutes';
import logo from 'assets/janregi_logo.jpeg';
import { useLoginHook } from 'hooks/auth/login.hook';

export const LoginPage: FC = memo(() => {
  const { handleSubmit, handleChange, errors, valid } = useLoginHook();
  return (
    <RootStyle onSubmit={handleSubmit}>
      <Columns space={16} position={'center'} height={'100%'} width={'100%'}>
        <img src={logo} alt={'logo'} width={400} />

        <InputGroup
          label={'メールアドレス'}
          name={'email'}
          required={true}
          error={errors['email']}
        >
          <Input
            type={'email'}
            placeholder={'店舗メールアドレス'}
            width={360}
            autoComplete={'email'}
            handleChange={handleChange('email')}
            isError={(errors['email']?.length ?? 0) > 0}
          />
        </InputGroup>
        <InputGroup
          label={'従業員メールアドレス'}
          name={'employeeEmail'}
          required={true}
          error={errors['employeeEmail']}
        >
          <Input
            type={'email'}
            placeholder={'従業員メールアドレス'}
            autoComplete={'name'}
            width={360}
            handleChange={handleChange('employeeEmail')}
            isError={(errors['employeeEmail']?.length ?? 0) > 0}
          />
        </InputGroup>
        <InputGroup
          label={'パスワード'}
          name={'password'}
          required={true}
          error={errors['password']}
        >
          <Input
            type={'password'}
            placeholder={'パスワード'}
            autoComplete={'password'}
            width={360}
            handleChange={handleChange('password')}
            isError={(errors['password']?.length ?? 0) > 0}
          />
        </InputGroup>
        <Button disabled={valid} type={'submit'}>
          ログイン
        </Button>
      </Columns>
    </RootStyle>
  );
});

const RootStyle = styled.form`
  margin: auto;
  height: 100%;
`;
