import { useNavigate } from 'react-router-dom';
import { AuthLogin } from 'domain/entity/auth.login';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UserLoginValidation } from 'validations/user.login.validation';
import { AuthRepository } from 'domain/repository/auth.repository';
import { useCookies } from 'react-cookie';
import { useValidation } from 'hooks/common/useValidation';
import { useProgress } from 'hooks/common/useProgress';
import { useShowMessage } from 'hooks/common/useShowMessage';

export const useLoginHook = () => {
  const navigate = useNavigate();
  const { errors, valid, validation } = useValidation();
  const { pushProgress, removeProgress } = useProgress();
  const { setMessageInfo, setMessageOpen } = useShowMessage();
  const [getForm, setForm] = useState<AuthLogin>({});
  const [, setCookie] = useCookies(['token']);
  useEffect(
    () => validation(getForm, UserLoginValidation),
    [getForm, validation],
  );

  const handleChange = (prop: string) =>
    useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...getForm, [prop]: event.target.value });
      },
      [getForm, setForm],
    );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      pushProgress();
      AuthRepository.login(getForm)
        .then((data) => {
          setCookie('token', data.token, { path: '/' });
          setMessageInfo({
            message: 'ログインに成功しました',
            status: 'success',
          });
          setMessageOpen(true);
          navigate('/');
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            setMessageInfo({
              message: 'ログインに失敗しました',
              status: 'error',
            });
          } else {
            setMessageInfo({
              message: 'しばらく時間をおいてもう一度お試しください',
              status: 'error',
            });
          }
          setMessageOpen(true);
        })
        .finally(removeProgress);
    },
    [getForm, pushProgress, removeProgress, setMessageInfo, setMessageOpen],
  );

  return { getForm, handleChange, handleSubmit, errors, valid };
};
