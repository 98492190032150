import { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';
import { blackColor } from 'common/themes/colors';

type Props = {
  color?: string;
  href?: string;
  onClick?: () => void;
  children: ReactNode;
};

export const Link: FC<Props> = memo((props) => {
  return (
    <RootStyle $color={props.color} href={props.href} onClick={props.onClick}>
      {props.children}
    </RootStyle>
  );
});

const RootStyle = styled.a<{ $color?: string }>`
  color: ${(props) => props.$color ?? blackColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
