import { FC, memo, ReactElement } from 'react';
import styled from 'styled-components';
import { blackColor } from 'common/themes/colors';

type Props = {
  htmlFor: string;
  children: ReactElement | string;
  color?: string;
  size?: number;
  fontWeight?: string;
};
export const Label: FC<Props> = memo((props) => {
  return (
    <RootStyle
      color={props.color}
      size={props.size}
      htmlFor={props.htmlFor}
      fontWeight={props.fontWeight}
    >
      {props.children}
    </RootStyle>
  );
});

type StyleProps = {
  color?: string;
  size?: number;
  fontWeight?: string;
};

const RootStyle = styled.label<StyleProps>`
  margin: 0;
  display: inline-block;
  color: ${(props: StyleProps) => props.color ?? blackColor};
  font-size: ${(props: StyleProps) =>
    props.size ? `${props.size}px` : '16px'};
  font-weight: ${(props: StyleProps) => props.fontWeight};
`;
