import React, { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  space?: number;
  position: 'start' | 'center' | 'end';
  width?: string;
  height?: string;
  children: ReactNode;
};
export const Columns: FC<Props> = memo((props) => {
  return (
    <RootStyle
      $position={props.position}
      $space={props.space}
      $height={props.height}
      $width={props.width}
    >
      {props.children}
    </RootStyle>
  );
});

const RootStyle = styled.div<{
  $height?: string;
  $width?: string;
  $position: 'start' | 'center' | 'end';
  $space?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$position};
  justify-content: center;
  gap: ${(props) => (props.$space ? `${props.$space}px` : 0)};
  width: ${(props: { $width?: string }) =>
    props.$width ? props.$width : 'fit-content'};
  height: ${(props: { $height?: string }) =>
    props.$height ? props.$height : 'fit-content'};
`;
