import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export type ProgressContextType = {
  isShow: number;
  setShow: Dispatch<SetStateAction<number>>;
};

export const ProgressContext = createContext<ProgressContextType>(
  {} as ProgressContextType,
);

export const ProgressProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [isShow, setShow] = useState<number>(0);

  return (
    <ProgressContext.Provider value={{ isShow, setShow }}>
      {children}
    </ProgressContext.Provider>
  );
};
