import { FC, memo } from 'react';
import { Columns, Label, Rows } from 'components/atoms';
import styled from 'styled-components';
import { getFormattedDate } from 'common/utils/date';

type Props = {
  latestUpdateAt: Date;
  latestSummaryCreatedAt?: string;
};
export const Timestamp: FC<Props> = memo((props) => {
  return (
    <Columns position={'start'} space={8}>
      <Rows space={8}>
        <DivStyled>
          <Label htmlFor={'latestUpdateAt'}>最終更新日時</Label>
        </DivStyled>
        <Label htmlFor={'salesTotal'}>:</Label>
        <span>
          {getFormattedDate(props.latestUpdateAt, 'yyyy/MM/dd hh:mm')}
        </span>
      </Rows>
      <Rows space={8}>
        <DivStyled>
          <Label htmlFor={'latestSummaryCreatedAt'}>日計表作成時刻</Label>
        </DivStyled>
        <Label htmlFor={'salesTotal'}>:</Label>
        <span>
          {props.latestSummaryCreatedAt &&
            getFormattedDate(
              new Date(props.latestSummaryCreatedAt),
              'yyyy/MM/dd hh:mm',
            )}
        </span>
      </Rows>
    </Columns>
  );
});
const DivStyled = styled.div`
  width: 112px;
`;
