import { FC, memo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Pages from 'pages';
import { ProgressProvider } from 'providers/useProgressProvider';
import { MessageInfoProvider } from 'providers/useMessageProvider';
import { Message, Progress } from 'components/modules/common';

export const RoutesApp: FC = memo(() => {
  return (
    <BrowserRouter>
      <MessageInfoProvider>
        <ProgressProvider>
          <Progress />
          <Message />
          <Routes>
            <Route key={'/'} path={'/'} element={<Pages.TopPage />} />
            <Route key={'/login'} path="/login" element={<Pages.LoginPage />} />
            <Route key={'/404'} path="*" element={<Pages.Page404 />} />
          </Routes>
        </ProgressProvider>
      </MessageInfoProvider>
    </BrowserRouter>
  );
});
