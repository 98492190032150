import { AuthLogin } from 'domain/entity/auth.login';
import { AuthToken } from 'domain/entity/auth.token';
import client from 'infrastructures/axios/axios.client';

export const AuthRepository = {
  login: async (req: AuthLogin): Promise<AuthToken> => {
    return await client
      .post<AuthToken>('/login', req)
      .then((data) => data.data);
  },
};
