import { FC, memo } from 'react';
import styled from 'styled-components';
import { Link, Rows } from 'components/atoms';
import { Container } from 'components/molecutes';
import { useHeader } from 'hooks/common/useHeader';
import logo from 'assets/HeaderLogo.png';

export const Header: FC = memo(() => {
  const { handlerLogout } = useHeader();
  return (
    <RootStyle>
      <Rows width={'100%'} height={'100%'} justifyContent={'space-between'}>
        <p></p>
        <img src={logo} alt={'logo'} height={'64px'} />
        <Container padding={'16px'}>
          <Link color={'#FFFFFF'} onClick={handlerLogout}>
            ログアウト
          </Link>
        </Container>
      </Rows>
    </RootStyle>
  );
});

const RootStyle = styled.div`
  height: 64px;
  width: 100%;
  background: #ec9635;
`;
