import axios from 'axios';
import Cookies from 'js-cookie';
const client = axios.create({
  baseURL: process.env.REACT_APP_URL,
});
client.defaults.headers.common['Content-Type'] = 'application/json';
// client.defaults.withCredentials = true;
export default client;

export const getToken = (): string | undefined => {
  return `Bearer ${Cookies.get('token')}`;
};
