import { FC, memo } from 'react';
import { Columns, Label, Rows } from 'components/atoms';
import styled from 'styled-components';

type Props = {
  salesTotal: number;
  freeSalesTotal: number;
};
export const SalesTotal: FC<Props> = memo((props) => {
  return (
    <Columns position={'start'} space={8}>
      <Rows space={8}>
        <DivStyled>
          <Label htmlFor={'salesTotal'} fontWeight={'bold'}>
            売上合計
          </Label>
        </DivStyled>
        <Label htmlFor={'salesTotal'} fontWeight={'bold'}>
          :
        </Label>
        <Label
          htmlFor={'salesTotalNumber'}
          fontWeight={'bold'}
        >{`${props.salesTotal.toLocaleString()}円`}</Label>
      </Rows>
      <Rows space={8}>
        <DivStyled>
          <Label htmlFor={'salesTotal'} fontWeight={'bold'}>
            フリー売上合計
          </Label>
        </DivStyled>
        <Label htmlFor={'salesTotal'} fontWeight={'bold'}>
          :
        </Label>
        <Label
          htmlFor={'salesTotalNumber'}
          fontWeight={'bold'}
        >{`${props.freeSalesTotal.toLocaleString()}円`}</Label>
      </Rows>
    </Columns>
  );
});

const DivStyled = styled.div`
  width: 112px;
`;
