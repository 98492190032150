import { FC, memo } from 'react';
import { Customer } from 'domain/entity/customer';
import { Label } from 'components/atoms';
import { Container } from 'components/molecutes';

type Props = {
  customer: Customer;
};
export const CustomerRow: FC<Props> = memo((props) => {
  return (
    <Container padding={'8px 4px'}>
      <Label htmlFor={'customerName'}>{props.customer.customerName}</Label>
    </Container>
  );
});
