import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const reducer = combineReducers({
  router: connectRouter(history),
});

// useSelectorでの型推論に必要なため、reducerの戻り値型をエクスポート
export type RootState = ReturnType<typeof reducer>;

export const store = configureStore({
  reducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(routerMiddleware(history));
  },
});
