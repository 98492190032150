import { FC, memo, ReactElement } from 'react';
import styled from 'styled-components';
import { blackColor, primaryColor, whiteColor } from 'common/themes/colors';

type Props = {
  children: ReactElement | string;
  type?: 'submit' | 'reset' | 'button';
  width?: number;
  disabled?: boolean;
  onSubmit?: () => void;
};
export const Button: FC<Props> = memo((props) => {
  return (
    <RootStyle
      disabled={props.disabled}
      type={props.type ?? 'button'}
      onClick={props.onSubmit}
    >
      {props.children}
    </RootStyle>
  );
});

const RootStyle = styled.button<{ width?: number }>`
  background: ${primaryColor};
  padding: 8px;
  border-radius: 8px;
  border: 0;
  color: ${whiteColor};
  font-weight: bold;
  width: ${(props: { width?: number }) =>
    props.width ? `${props.width}px` : 'auto'};
  &:hover {
    border: 1px solid ${blackColor};
  }
  &:disabled {
    opacity: 0.7;
    border: none;
  }
`;
