import { ValidationScheme } from 'common/valitation/validation';

export const UserLoginValidation: {
  [keyword: string]: ValidationScheme;
} = {
  email: {
    name: '店舗メールアドレス',
    scheme: [{ name: 'IsEmail' }, { name: 'IsNotEmpty' }],
  },
  employeeEmail: {
    name: '従業員メールアドレス',
    scheme: [{ name: 'IsEmail' }, { name: 'IsNotEmpty' }],
  },
  password: {
    name: 'パスワード',
    scheme: [{ name: 'IsNotEmpty' }],
  },
};
