import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
export type MessageInfo = {
  message: string | null;
  status: 'info' | 'warning' | 'success' | 'error';
};
export type MessageInfoContextType = {
  messageInfo: MessageInfo | null;
  setMessageInfo: Dispatch<SetStateAction<MessageInfo | null>>;
  isOpen: boolean;
  setMessageOpen: Dispatch<SetStateAction<boolean>>;
};

export const MessageInfoContext = createContext<MessageInfoContextType>(
  {} as MessageInfoContextType,
);

export const MessageInfoProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [messageInfo, setMessageInfo] = useState<MessageInfo | null>(null);
  const [isOpen, setMessageOpen] = useState(false);
  return (
    <MessageInfoContext.Provider
      value={{ messageInfo, setMessageInfo, isOpen, setMessageOpen }}
    >
      {children}
    </MessageInfoContext.Provider>
  );
};
