import { FC, memo, ReactElement } from 'react';
import { Rows, Label, Columns, Spacer } from 'components/atoms';
import styled from 'styled-components';
import { dangerColor } from 'common/themes/colors';

type Props = {
  label: string;
  name: string;
  error?: string;
  required?: boolean;
  children: ReactElement;
};
export const InputGroup: FC<Props> = memo((props) => {
  return (
    <Columns space={8} position={'start'}>
      <Rows>
        <Spacer size={0} />
        <Label htmlFor={props.name} size={12}>
          {props.label}
        </Label>
        <RequiredStyle>{props.required ? '*' : ''}</RequiredStyle>
      </Rows>
      {props.children}
      <RequiredStyle>{props.error}</RequiredStyle>
    </Columns>
  );
});

const RequiredStyle = styled.p`
  color: ${dangerColor};
  font-size: 12px;
  margin: 0;
  width: fit-content;
`;
