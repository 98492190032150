import { FC, memo } from 'react';
import { Columns } from 'components/atoms';
import { summaryLastStatus } from 'common/codes/table.status';
import { FreeGameDetails } from 'domain/entity';
import styled from 'styled-components';

type Props = {
  freeGameDetails: FreeGameDetails[];
  freeGameFirstDealerSeat: number;
};
export const FreeDetail: FC<Props> = memo((props) => {
  return (
    <Columns position={'center'} space={16}>
      <SubTitleStyle>メンバー</SubTitleStyle>
      <table>
        {props.freeGameDetails.map((detail, i) => (
          <tr key={i}>
            <td>
              {detail.seatNo === props.freeGameFirstDealerSeat && <>起</>}
            </td>
            <td>
              {detail.lastStatus > 0 && summaryLastStatus[detail.lastStatus]}
            </td>
            <td>{detail.playerName}</td>
            <td>{detail.winStreaks > 0 && <>{detail.winStreaks}連勝中</>}</td>
          </tr>
        ))}
      </table>
    </Columns>
  );
});

const SubTitleStyle = styled.span`
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
`;
