import { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  padding?: string;
  border?: string;
  width?: string;
  children: ReactNode;
  radius?: string;
};
export const Container: FC<Props> = memo((props) => {
  return (
    <RootStyle
      $padding={props.padding}
      $border={props.border}
      width={props.width}
      borderRadius={props.radius}
    >
      {props.children}
    </RootStyle>
  );
});

const RootStyle = styled.div<{
  $padding?: string;
  $border?: string;
  width?: string;
  borderRadius?: string;
}>`
  padding: ${(props) => props.$padding};
  border: ${(props) => props.$border};
  width: ${(props: { width?: string }) =>
    props.width ? props.width : 'fit-content'};
  border-radius: ${(props) => props.borderRadius ?? 0};
`;
