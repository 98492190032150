import React, { CSSProperties, FC, memo, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  space?: number;
  width?: string;
  height?: string;
  justifyContent?: string;
  alignItem?: string;
  style?: CSSProperties;
  children: ReactNode;
};
export const Rows: FC<Props> = memo((props) => {
  return (
    <RootStyle
      $width={props.width}
      $height={props.height}
      $alignItem={props.alignItem}
      $space={props.space}
      $justifyContent={props.justifyContent}
      style={props.style}
    >
      {props.children}
    </RootStyle>
  );
});

const RootStyle = styled.div<{
  $height?: string;
  $width?: string;
  $space?: number;
  $alignItem?: string;
  $justifyContent?: string;
}>`
  display: flex;
  display: -webkit-flex;
  align-items: ${(props) => props.$alignItem ?? 'center'};
  justify-content: ${(props) => props.$justifyContent};
  width: ${(props: { $width?: string }) =>
    props.$width ? props.$width : 'fit-content'};
  height: ${(props: { $height?: string }) =>
    props.$height ? props.$height : 'fit-content'};
  gap: ${(props) => (props.$space ? `${props.$space}px` : 0)};
`;
