import { FC, memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useShowMessage } from 'hooks/common/useShowMessage';
import { blackColor, dangerColor, whiteColor } from 'common/themes/colors';

export const Message: FC = memo(() => {
  const { messageInfo, isOpen, setMessageOpen } = useShowMessage();
  const handleClose = useCallback(
    () => setMessageOpen(false),
    [setMessageOpen],
  );
  useEffect(() => {
    // 5秒後にメッセージを閉じる
    if (isOpen) {
      setTimeout(() => {
        setMessageOpen(false);
      }, 5000);
    }
  }, [isOpen, setMessageOpen]);
  return (
    <RootStyle
      onClick={handleClose}
      $isOpen={isOpen}
      $status={messageInfo?.status}
    >
      {messageInfo?.message}
    </RootStyle>
  );
});
const colorByStatus = (
  status?: 'info' | 'warning' | 'success' | 'error' | undefined,
) => {
  switch (status) {
    case undefined:
      return 'transparent';
    case 'success':
      return '#00FFFF';
    default:
      return dangerColor;
  }
};

const RootStyle = styled.div<{
  $isOpen: boolean;
  $status?: 'info' | 'warning' | 'success' | 'error' | undefined;
}>`
  margin: 16px;
  padding: 16px;
  position: fixed;
  border-radius: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${(props) => (props.$status === 'error' ? whiteColor : blackColor)};
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  background: ${(props) => colorByStatus(props.$status)};
`;
