import { FC, memo } from 'react';
import styled from 'styled-components';

type Props = {
  size: number;
  horizontal?: boolean;
};
export const Spacer: FC<Props> = memo((props) => {
  return <RootStyle horizontal={props.horizontal} size={props.size} />;
});

const RootStyle = styled.div<Props>`
  width: ${(props: Props) => (props.horizontal ? `${props.size}px` : '0')};
  height: ${(props: Props) => (props.horizontal ? '0' : `${props.size}px`)};
  display: ${(props: Props) => (props.horizontal ? 'inline-block' : 'block')};
  flex-shrink: 0;
`;
