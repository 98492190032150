import { useCallback, useState } from 'react';
import validator from 'validator';
import { isIP } from 'class-validator';

export const useValidation = () => {
  const [errors, setError] = useState<{ [key: string]: string }>({});
  const [valid, setValid] = useState(false);

  const validation = useCallback(
    (
      obj: { [keyword: string]: string | number | boolean | undefined },
      params: { [keyword: string]: ValidationScheme },
    ) => {
      const data: { [key: string]: string } = {};
      let isValid = false;
      Object.keys(params).forEach((key) => {
        params[key].scheme.map((k) => {
          switch (k.name) {
            case 'IsNotEmpty':
              if (IsNotEmpty(obj[key])) {
                data[key] = '必須項目';
                isValid = true;
              }
              break;
            case 'MinLength':
              if (MinLength(obj[key], k.options?.num)) {
                data[key] = `${k.options}以上で入力してください`;
                isValid = true;
              }
              break;
            case 'MaxLength':
              if (MaxLength(obj[key], k.options?.num)) {
                data[key] = `${k.options?.num}文字以内で入力してください`;
                isValid = true;
              }
              break;
            case 'IsEmail':
              if (IsEmail(obj[key])) {
                data[key] = `メールアドレスの形式で入力してください`;
                isValid = true;
              }
              break;
            case 'IsPassword':
              if (IsPassword(obj[key])) {
                data[key] = `8文字以上で大小英数字記号それぞれ1文字以上`;
                isValid = true;
              }
              break;
            case 'IsCreditCard':
              if (IsCreditCard(obj[key])) {
                data[key] = 'クレジットカード番号の形式で入力してください';
                isValid = true;
              }
              break;
            case 'IsSecurityCode':
              if (IsSecurityCode(obj[key])) {
                data[key] = '3-4桁の数字で入力してください';
                isValid = true;
              }
              break;
            case 'IsExpiry':
              if (IsExpiry(obj[key])) {
                data[key] = 'MMYYの形式で未来の年月を入力してください';
                isValid = true;
              }
              break;
            case 'IsCreditName':
              if (IsCreditName(obj[key])) {
                data[key] = 'アルファベットで入力してください';
                isValid = true;
              }
              break;
            case 'Equal':
              if (
                k.options?.property &&
                obj[key] !== obj[k.options?.property]
              ) {
                data[key] = `${
                  params[k.options?.property].name
                }と一致してません`;
                isValid = true;
              }
              break;
            case 'In':
              if (k.options !== undefined && IsIn(obj[key], k.options?.array)) {
                data[key] =
                  k.options?.array?.join(',') + 'の中から入力してください';
                isValid = true;
              }
              break;
            case 'IsIP':
              if (!isIP(obj[key])) {
                data[key] = 'IPアドレスの形式でありません';
                isValid = true;
              }

              break;
            default:
              break;
          }
          if (obj[key] === undefined) {
            delete data[key];
          }
          setError(data);
          setValid(isValid);
        });
      });
    },
    [],
  );
  return { validation, errors, valid };
};

export interface ValidationScheme {
  name: string;
  scheme: Scheme[];
}

interface Scheme {
  name:
    | 'IsNotEmpty'
    | 'MinLength'
    | 'MaxLength'
    | 'IsEmail'
    | 'IsPassword'
    | 'IsCreditCard'
    | 'IsSecurityCode'
    | 'IsExpiry'
    | 'IsCreditName'
    | 'IsIP'
    | 'In'
    | 'Equal';
  options?: Options;
}

type Options = {
  num?: number;
  array?: number[] | string[];
  property?: string;
};

const IsNotEmpty = (value: string | number | boolean | undefined): boolean => {
  if (value === undefined) return true;
  return typeof value === 'string' && value.length === 0;
};

const MinLength = (
  value: string | number | boolean | undefined,
  num?: number,
): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return false;
  if (num === undefined) return false;
  return value.length < num;
};

const MaxLength = (
  value: string | number | boolean | undefined,
  num?: number,
): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return false;
  if (num === undefined) return false;
  return value.length > num;
};

const IsEmail = (value: string | number | boolean | undefined): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return false;
  return !validator.isEmail(value);
};

const IsPassword = (value: string | number | boolean | undefined): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return false;
  return !validator.isStrongPassword(value);
};

const IsIn = (
  value: string | number | boolean | undefined,
  array?: any[],
): boolean => {
  if (value === undefined || array === undefined || typeof value === 'boolean')
    return false;
  if (typeof value === 'string') return IsInString(value, array);
  return IsInNumber(value, array);
};

const IsInString = (value: string, array: string[]): boolean => {
  return !array.includes(value);
};

const IsInNumber = (value: number, array: number[]): boolean => {
  return !array.includes(value);
};

const IsCreditCard = (
  value: string | number | boolean | undefined,
): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return true;
  return !validator.isCreditCard(value);
};

const IsSecurityCode = (
  value: string | number | boolean | undefined,
): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return true;
  const reg = /^[0-9]{3,4}$/g;
  return !reg.test(value);
};

const IsExpiry = (value: string | number | boolean | undefined): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return true;
  const reg = /^[0-9]{4}$/g;
  if (!reg.test(value)) return true;
  const month = value.substr(0, 2);
  const year = '20' + value.substr(2, 2);
  if (!(Number(month) >= 1 && Number(month) <= 12)) {
    return true;
  }
  const today = new Date();
  return (
    Number(year + month) < today.getFullYear() * 100 + (today.getMonth() + 1)
  );
};

const IsCreditName = (
  value: string | number | boolean | undefined,
): boolean => {
  if (
    value === undefined ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value.length === 0
  )
    return true;
  const reg = /^[a-zA-Z]*$/;
  return !reg.test(value);
};
