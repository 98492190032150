export const tableStatus: { [key: number]: string } = {
  1: 'フリー卓',
  2: 'セット卓',
  3: '空き',
};

export const summaryLastStatus: { [key: number]: string } = {
  1: 'L',
  2: 'M',
};
