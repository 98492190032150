import { FC, memo, ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactElement;
  gap?: number;
  padding?: number;
};
export const Grid: FC<Props> = memo((props) => {
  return <RootStyle $padding={props.padding}>{props.children}</RootStyle>;
});

const RootStyle = styled.div<{ $padding?: number; gap?: number }>`
  display: inline-flex;
  width: fit-content;
  padding: ${(props) => (props.$padding ? `${props.$padding}px` : 0)};
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${(props) => props.gap ?? 32}px;
  flex-wrap: wrap;
`;
