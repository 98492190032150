import { useEffect, useState } from 'react';
import { Sale } from 'domain/entity/sale';
import { TableList } from 'domain/entity';
import { CustomerList } from 'domain/entity/customer';
import { TopRepository } from 'domain/repository/top.repository';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useProgress } from 'hooks/common/useProgress';
import { useShowMessage } from 'hooks/common/useShowMessage';

export const useTopHook = () => {
  const { setMessageInfo, setMessageOpen } = useShowMessage();
  const { pushProgress, removeProgress } = useProgress();
  const navigate = useNavigate();
  const [, , deleteCookie] = useCookies(['token']);
  const [sale, setSale] = useState<Sale>({
    saleTotal: 0,
    saleTotalFreeGame: 0,
  });
  const [tables, setTables] = useState<TableList>({
    results: [],
  });
  const [customers, setCustomers] = useState<CustomerList>({
    results: [],
  });
  const [leaveCustomers, setLeaveCustomers] = useState<CustomerList>({
    results: [],
  });

  useEffect(() => {
    (async () => {
      pushProgress();
      await Promise.all([
        TopRepository.getSale(),
        TopRepository.getTables(),
        TopRepository.getCustomers(),
        TopRepository.getLeaveCustomers(),
      ])
        .then(([sale, tables, customers, leaveCustomers]) => {
          setSale(sale);
          setTables(tables);
          setCustomers(customers);
          setLeaveCustomers(leaveCustomers);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            setMessageInfo({
              message: '認証に失敗しました',
              status: 'error',
            });
            setMessageOpen(true);
            deleteCookie('token', { path: '/' });
            navigate('/login');
          } else if (error?.response?.status !== undefined) {
            setMessageInfo({
              message: '情報の取得に失敗しました',
              status: 'error',
            });
            setMessageOpen(true);
          } else {
            setMessageInfo({
              message: 'しばらく時間をおいてもう一度お試しください',
              status: 'error',
            });
            setMessageOpen(true);
          }
        })
        .finally(removeProgress);
    })();
  }, []);

  return { sale, tables, customers, leaveCustomers };
};
